import http from 'http.js';
import {format} from 'date-fns';

import config from './Mh1config';

export class Mh1BookingApi {
    extractBookingIntegrationIds(items, key) {
        const bookingIds = [];
        if (items.length === 0) return bookingIds;

        items.forEach((item) => {
            if (item?.bookingIntegration) {
                //const keys = Object.keys(item.bookingIntegration).map(key => key.toLowerCase());
                if (item.bookingIntegration?.name.toLowerCase() === key) {
                    bookingIds.push(item.bookingIntegration.bookingId);
                }
            }
        });

        return bookingIds;
    }

    async fetchAvailabilitySummary(items) {
        const bookingIds = this.extractBookingIntegrationIds(items, 'mh1');
        if (!bookingIds.length) {
            return [];
        }
        return await this.callAvailabilitySummary(bookingIds);
    }

    // API calls
    /**
   * 
   * @param practitionerIds: string[]
   * @returns availabilitySummary: {
                id: number 
                next_available: date
                slot_id: string
              }[]
   */
    async callAvailabilitySummary(practitionerIds) {
        const mh1Url =
            document.body?.dataset?.environment === 'prod'
                ? config.url.prod
                : config.url.test;

        try {
            const response = await http.get({
                url: `${mh1Url}/${config.endpoints.availabilitySummary}`,
                headers: config.headers,
                data: {
                    practitionerIds: practitionerIds,
                    startDate: format(new Date(), 'yyyy-MM-dd'),
                    // [TODO:CHECK] This value is hard coded at the moment
                    // the parameter will be removed in the future
                    noOfDays: 60,
                },
            });

            let availabilitySummary = [];
            if (response?.data?.availability?.practitioners?.length) {
                availabilitySummary =
                    response.data.availability?.practitioners;
            }

            return availabilitySummary;
        } catch (error) {
            throw `${mh1Url} is not active`;
        }
    }
}
